import * as anchor from "@project-serum/anchor";

export const programId = new anchor.web3.PublicKey(
  "cndyAnrLdpjq1Ssp1z8xxDsB8dxe7u4HL5Nxi2K5WXZ"
);

export const NETWORK = "mainnet";
// export const NETWORK = "devnet";

export const config = new anchor.web3.PublicKey(
  "9bfgrt6WfPCfneVsDWJdCzdCuFYpeGdBP5973SbwQbk8"
);
export const configUUID = "9bfgrt";

export const candyMachineOwner = new anchor.web3.PublicKey(
  "Lum9QdS3MUyGLvLpJgvhHjAZ5nTWpjsxRA2u5Nnkutz"
);
